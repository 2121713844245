[data-v-bd9f137a] .el-tree {
  height: 60vh !important;
  max-height: 60vh !important;
  overflow: scroll;
}
[data-v-bd9f137a] .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
[data-v-bd9f137a] .el-dialog {
  margin-bottom: 0px !important;
}